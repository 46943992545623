import { AxiosRequestConfig } from "axios";
import { CreatePostingModel } from "../Models/CreatePostingModel";
import { PostingModel } from "../Models/PostingModel";
import { Moment } from "moment";
import { UpdatePostingModel } from "../Models/UpdatePostingModel";
import { StorageHelper } from "./StorageHelper";
import { CallManager } from "./CallManager/CallManager";
import { PastPostingModel } from "../Models/PastPostingModel";
import { RequestModel } from "../Models/RequestModel";
import { RatingModel } from "../Models/RatingModel";
import { iInitialPostingData } from "../Components/Routing/Facade/Company/EditPosting/EditPostingTypes";
import { EventTypeModel } from "../Models/EventTypeModel";
import { getLanguage } from "./LanguageHelper";
import {
  getTimeBetweenDateTimes,
  getNowAsDbString,
  addToDate,
} from "./DateTimeHelper";
import * as Sentry from "@sentry/react";

export class PostingHelper {
  storageHelper = new StorageHelper();
  clientKey = "04216571796E48ABB8FD781DEA966A4F";

  async createPosting(posting: CreatePostingModel) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/posting/post",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
        data: posting,
      };
      let response = await CallManager.Instance.makeCall(config, true);
      return response.data;
    } catch (error: any) {
      Sentry.captureException(error, {
        tags: {
          "business-capability": "posting-creation",
        },
      });
      if (error && error.response) {
        let errorArr = error.response.data;
        console.error(errorArr);
        return errorArr;
      }
      return getLanguage(469, "unknown error");
    }
  }

  async updatePosting(posting: UpdatePostingModel) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "PUT",
        url: "/posting/put",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
        data: posting,
      };
      let response = await CallManager.Instance.makeCall(config, true);
      return response.data;
    } catch (error: any) {
      Sentry.captureException(error, {
        tags: {
          "business-capability": "posting-update",
        },
      });
      if (error && error.response && error.response.data) {
        let errorArr = error.response.data;
        console.error(errorArr);
        return errorArr;
      } else {
        return [getLanguage(469, "Unknown error")];
      }
    }
  }

  async getAllPostings() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/posting",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);

      if (response.data.length) return response.data as PostingModel[];

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getAllPastPostingsWhichAreOverThirtyDaysOld(
    page: number,
    resultsPerPage: number,
    showHidden: boolean,
    jobDateFrom?: Date,
    jobDateTo?: Date
  ) {
    try {
      let config: AxiosRequestConfig = {
        method: "GET",
        url:
          "/history/archivedjobs?page=" +
          page +
          "&resultsperpage=" +
          resultsPerPage +
          "&showhidden=" +
          (showHidden ? "true" : "false") +
          (jobDateFrom ? "&jobDateFrom=" + jobDateFrom.toISOString() : "") +
          (jobDateTo ? "&jobDateTo=" + jobDateTo.toISOString() : ""),
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);
      //FIXME: Add typing
      return response.data || false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getAllPastPostingsThatAreMaxThirtyDaysOld(
    page: number,
    resultsPerPage: number,
    showHidden: boolean
  ) {
    try {
      let config: AxiosRequestConfig = {
        method: "GET",
        url:
          "/history/recentjobs?page=" +
          page +
          "&resultsperpage=" +
          resultsPerPage +
          "&showhidden=" +
          showHidden,
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);
      //FIXME: Add typing
      return response.data || false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async archivePosting(postingId: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/posting/archiveposting",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          authToken,
        },
        data: { postingId },
      };

      let response = await CallManager.Instance.makeCall(config, false);
      return response.data;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async deleteOrClosePosting(postingId: string, cancelJob: boolean) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/DeletePosting",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
        data: {
          CancelJobs: cancelJob,
          PostingId: postingId,
          Reason: "",
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);
      return response.status === 200;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async reopenPosting(postingId: string, AmountNeeded: number) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/ReopenPosting",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
        data: {
          AmountNeeded: AmountNeeded,
          PostingId: postingId,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);
      return response.data || false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getAverageSalary(date: Moment) {
    try {
      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/api/posting/salarystatsfordate/" + date.toISOString(),
        withCredentials: true,
      };

      let response = await CallManager.Instance.makeCall(config, false);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async voucherValidation(voucher: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/voucher/" + voucher,
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);
      return response.data || false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async freeChabbersAvailable() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/voucher",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);
      return response.data || false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getJobCSV(postingId: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/csv/HiredFreelancersCsv/" + postingId,
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);
      return response.data || false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getWholeDayCSV(postingDate: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/csv/HiredFreelancersCsvFullDay/" + postingDate,
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };

      let response = await CallManager.Instance.makeCall(config, true);

      return response.data || false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getPosting(id: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/posting?id=" + id,
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);
      return response.data || false;
    } catch (error: any) {
      return false;
    }
  }

  async getPastPostings(
    page: number,
    getArchived: boolean,
    getHidden: boolean,
    jobDateFrom?: Date,
    jobDateTo?: Date
  ) {
    let pastPostings: PastPostingModel = new PastPostingModel({});
    if (!getArchived) {
      pastPostings = await this.getAllPastPostingsThatAreMaxThirtyDaysOld(
        page,
        10,
        getHidden
      );
    } else {
      pastPostings = await this.getAllPastPostingsWhichAreOverThirtyDaysOld(
        page,
        10,
        getHidden,
        jobDateFrom,
        jobDateTo
      );
    }

    return pastPostings;
  }

  async getRating(id: string) {
    try {
      let config: AxiosRequestConfig = {
        url: `/rating/GetExistingCreatedForRequestId/${id}`,
        method: "GET",
      };
      let response = await CallManager.Instance.makeCall(config, true);
      if (response.data == null) return null;
      return new RatingModel(response.data);
    } catch (error: any) {
      console.error(error);

      console.log("error", JSON.parse(JSON.stringify(error)));
      return null;
    }
  }

  async getInitialData(id: string): Promise<iInitialPostingData | null> {
    try {
      let config: AxiosRequestConfig = {
        url: `/api/posting/initialdata?id=${id}`,
        method: "GET",
      };
      let response = await CallManager.Instance.makeCall(config, false);
      return response.data;
    } catch (error: any) {
      console.log(JSON.parse(JSON.stringify(error)));

      return null;
    }
  }

  async getEventTypes() {
    try {
      let config: AxiosRequestConfig = {
        url: "/api/posting/eventtypes",
        method: "GET",
      };

      let response = await CallManager.Instance.makeCall(config, false);
      return response.data.map((x: EventTypeModel) => new EventTypeModel(x));
    } catch (error: any) {
      console.log(JSON.parse(JSON.stringify(error)));

      return null;
    }
  }

  getTimeTillCloseDueToManyApplicants(posting: PostingModel): number | null {
    if (posting.Deactivated) {
      return null;
    }

    if (!posting.DateForManyRequestsWarningMailUTC) {
      return null;
    }

    if (
      posting.DateForLastCompanyActionUTC &&
      posting.DateForLastCompanyActionUTC >
        posting.DateForManyRequestsWarningMailUTC
    ) {
      // Company have taken action since last warning
      return null;
    }

    let expectedCloseDateTime = addToDate(
      posting.DateForManyRequestsWarningMailLocal,
      72,
      "hours"
    );

    return getTimeBetweenDateTimes(
      getNowAsDbString(),
      expectedCloseDateTime,
      "hours"
    );
  }
}

export async function orderPostingsOrRequests(
  postingArrayToSort: PostingModel[] | null,
  requestArrayToSort: RequestModel[] | null,
  ascending: boolean
) {
  let arrayToSort;

  if (postingArrayToSort) {
    arrayToSort = postingArrayToSort;
    if (ascending) {
      arrayToSort.sort(function (a: PostingModel, b: PostingModel) {
        if (a.EndAtLocal && b.EndAtLocal) {
          if (a.EndAtLocal < b.EndAtLocal) {
            return -1;
          }
          if (a.EndAtLocal > b.EndAtLocal) {
            return 1;
          }
          return 0;
        }
        return 0;
      });
    } else {
      arrayToSort.sort(function (a, b) {
        if (a.EndAtLocal && b.EndAtLocal) {
          if (a.EndAtLocal > b.EndAtLocal) {
            return -1;
          }
          if (a.EndAtLocal < b.EndAtLocal) {
            return 1;
          }
          return 0;
        }
        return 0;
      });
    }
  }
  if (requestArrayToSort) {
    arrayToSort = requestArrayToSort;
    if (ascending) {
      arrayToSort.sort(function (a, b) {
        if (a.EndDateTimeLocal && b.EndDateTimeLocal) {
          if (a.EndDateTimeLocal < b.EndDateTimeLocal) {
            return -1;
          }
          if (a.EndDateTimeLocal > b.EndDateTimeLocal) {
            return 1;
          }
          return 0;
        }
        return 0;
      });
    } else {
      arrayToSort.sort(function (a, b) {
        if (a.EndDateTimeLocal && b.EndDateTimeLocal) {
          if (a.EndDateTimeLocal > b.EndDateTimeLocal) {
            return -1;
          }
          if (a.EndDateTimeLocal < b.EndDateTimeLocal) {
            return 1;
          }
          return 0;
        }
        return 0;
      });
    }
  }

  return arrayToSort;
}
