import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { FreelancerModel } from "../Models/FreelancerModel";
import { StorageHelper } from "./StorageHelper";

export const getFreelancerProfileDetails = async (
  id: string
): Promise<AxiosResponse<FreelancerModel>> => {
  const authToken = new StorageHelper().getAccessToken();

  return await Axios.get<FreelancerModel>(`/api/freelancer/${id}`, {
    headers: { "Content-Type": "application/json", authToken },
  });
};

export const getSalaryPerSeries = async (
  id: string
): Promise<AxiosResponse<any>> => {
  const authToken = new StorageHelper().getAccessToken();

  return await Axios.get<FreelancerModel>(`/api/posting/${id}/salary-per-series`, {
    headers: { "Content-Type": "application/json", authToken },
  });
};
