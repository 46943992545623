import React from "react";
import { MenuItem } from "@material-ui/core";
import moment from "moment";
//@ts-ignore
import { isPostalCode, isNumeric, PostalCodeLocale } from "validator";
import { LocalDataModel } from "../../../../../Models/LocalDataModel";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { UpdatePostingModel } from "../../../../../Models/UpdatePostingModel";
import { CreatePostingModel } from "../../../../../Models/CreatePostingModel";
import { PostingHelper } from "../../../../../Helpers/PostingHelper";
import { PostingTemplateModel } from "../../../../../Models/PostingTemplateModel";
import { changeToLocalDecimal } from "../../../../../Helpers/LocalizationHelper";
import { ChatHelper } from "../../../../../Helpers/ChatHelper";
import {
  iEditPostingState,
  iAdditionalInfo,
  iInformationForChabber,
  JobDetailsHolder,
  FormStates,
} from "./EditPostingTypes";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import emojiRegex from "emoji-regex";

export function getStartTimes(
  fieldIndex: number,
  jobDetails: JobDetailsHolder,
  formState: FormStates
) {
  let lastEnd = -1;
  if (fieldIndex !== -1) {
    if (fieldIndex === 0) {
      lastEnd = jobDetails.endTime;
    } else {
      lastEnd = jobDetails.salaryIntervals[fieldIndex - 1].timeInHalfHours;
    }
  }

  if (lastEnd === -1 && formState !== FormStates.preparing) {
    if (
      formState === FormStates.loadFormWithChabbers ||
      formState === FormStates.loadClosedForm
    ) {
      let time = moment(jobDetails.StartAtLocal).format("HH:mm");

      return [
        <MenuItem disabled key={"current" + time} value={jobDetails.startTime}>
          {time}
        </MenuItem>,
      ];
    }
  }

  let today = moment();
  let date = moment(jobDetails.date);
  let arr: JSX.Element[] = [];
  if (today.isSame(date, "date")) {
    // The date is today
    for (let index = 1; index < 100; index++) {
      let newDate = moment(jobDetails.date);

      if (index >= lastEnd) {
        let minutes = newDate.get("minutes") + index * 30;
        newDate.set("minutes", minutes);
        let time = newDate.format("HH:mm");

        let earliest = today.get("hours") * 2 + 4;
        earliest += today.get("minutes") > 30 ? 2 : 1;

        if (
          formState === FormStates.loadForm &&
          index === jobDetails.startTime &&
          index < earliest
        ) {
          arr.push(
            <MenuItem key={"current" + time} value={index}>
              {time}
            </MenuItem>
          );
          arr.push(
            <MenuItem disabled key={1337} value="Nah!">
              Earliest allowed
            </MenuItem>
          );
        }

        if (
          fieldIndex === -1 &&
          index >= earliest &&
          newDate.get("date") === jobDetails.date.get("date")
        ) {
          let rand = Math.random().toString(36).substring(7);
          arr.push(
            <MenuItem key={"start" + time + rand} value={index}>
              {time}
            </MenuItem>
          );
        } else if (
          fieldIndex !== -1 &&
          index >= earliest &&
          index <= jobDetails.startTime + 36
        ) {
          if (index === 48) {
            arr.push(
              <MenuItem disabled key={7007} value="Nope!">
                Næste dag
              </MenuItem>
            );
          }

          let rand = Math.random().toString(36).substring(7);
          arr.push(
            <MenuItem key={"start" + time + rand} value={index}>
              {time}
            </MenuItem>
          );
        }
      }
    }
  } else {
    arr.push(
      <MenuItem key={"start"} value={0}>
        {"00:00"}
      </MenuItem>
    );
    for (let index = 1; index < 100; index++) {
      let newDate = moment(jobDetails.date);
      let minutes = newDate.get("minutes") + index * 30;
      newDate.set("minutes", minutes);
      if (newDate.get("date") === jobDetails.date.get("dates")) {
        let time = newDate.format("HH:mm");

        let rand = Math.random().toString(36).substring(7);
        arr.push(
          <MenuItem key={"start" + time + rand} value={index}>
            {time}
          </MenuItem>
        );
      }
    }
  }
  return arr;
}

export function getEndTimes(index: number, jobDetails: any, formState: any) {
  let startTime = 0;
  if (index < 0) {
    startTime = jobDetails.startTime;
  } else if (index === 0) {
    startTime = jobDetails.endTime;
  } else {
    startTime = jobDetails.salaryIntervals[index - 1].timeInHalfHours;
  }
  let arr: JSX.Element[] = [];
  let switchedDay = false;

  for (let index = startTime + 1; index < jobDetails.startTime + 36; index++) {
    let currentDate = moment(jobDetails.date);
    currentDate.set("minutes", index * 30);

    if (
      switchedDay === false &&
      currentDate.get("dates") !== jobDetails.date.get("dates")
    ) {
      arr.push(
        <MenuItem disabled key={7007} value="Nope!">
          Næste dag
        </MenuItem>
      );
      switchedDay = true;
    }
    if ((index * 30) / 60 < jobDetails.startTime + 36) {
      let newDate = moment();
      newDate.set("hours", 0);
      newDate.set("minutes", 0);
      newDate.set("minutes", index * 30);

      let time = newDate.format("HH:mm");
      arr.push(
        <MenuItem key={"end" + time + index} value={index}>
          {time}
        </MenuItem>
      );
    }
  }
  return arr;
}

export function ValidateValues(name: string, value: any, state: any) {
  let storageHelper = new StorageHelper();
  let errors: any = {};
  switch (name) {
    case "amountNeeded":
      if (Number(value).toString() !== value.toString()) {
        errors[name] = {
          message: getLanguage(165, "Must be a number"),
          isErr: true,
        };
      } else if (value < 1) {
        errors[name] = {
          message: getLanguage(703, "Can not be less than 1"),
          isErr: true,
        };
      } else if (
        state.formState === "loadFormWithChabbers" &&
        state.originalPosting &&
        value < state.originalPosting.AmountNeeded
      ) {
        errors[name] = {
          message: getLanguage(704, "no less than original amount").replace(
            "{{chabberAmount}}",
            state.originalPosting.AmountNeeded
          ),
          isErr: true,
        };
      } else {
        errors[name] = { message: "", isErr: false };
      }

      break;
    case "salaryHour":
      if (!isNumeric(value.toString())) {
        errors[name] = {
          message: getLanguage(165, "Must be a number"),
          isErr: true,
        };
      } else if (value < state.minimumHourlyWage / 100) {
        errors[name] = {
          message: getLanguage(705).replace(
            "{{minimumWage}}",
            changeToLocalDecimal((state.minimumHourlyWage / 100).toFixed(2))
          ),
          isErr: true,
        };
      } else if (
        state.formState !== "newForm" &&
        state.originalPosting &&
        value < state.originalPosting.SalaryPerHour / 100
      ) {
        errors[name] = {
          message: getLanguage(706).replace(
            "{{originalWage}}",
            changeToLocalDecimal(
              (state.originalPosting.SalaryPerHour / 100).toFixed(2)
            )
          ),
          isErr: true,
        };
      } else {
        errors[name] = { message: "", isErr: false };
      }
      break;
    case "city":
    case "jobDescription":
        if (value != null || value.length >= 0) {
          const regex = emojiRegex();
          if (regex.test(value)) {
            errors[name] = { message: "Emoji is forbidden", isErr: true };
          } else {
            errors[name] = { message: "", isErr: false };
          }
        } else {
          errors[name] = { message: "", isErr: false };
        }
      break;
    case "address":
      if (value == null || value.length <= 0) {
        errors[name] = { message: getLanguage(153, "Required"), isErr: true };
      } else {
        errors[name] = { message: "", isErr: false };
      }
      break;
    case "zip":
      let localData = storageHelper.getFacadeLocalization() as LocalDataModel;
      let countryCode = localData.country.TwoCharacterCountryCode.toUpperCase();
      if (
        !isPostalCode(
          value,
          countryCode && countryCode !== ""
            ? (countryCode as PostalCodeLocale)
            : "DK"
        )
      ) {
        errors[name] = {
          message: getLanguage(707, "zip code is invalid"),
          isErr: true,
        };
      } else {
        errors[name] = { message: "", isErr: false };
      }
      break;
  }

  return errors;
}

export function salaryIntervalsToString(jobDetails: any) {
  let intervals: { timeInHalfHours: number; salaryPerHour: number }[] =
    jobDetails.salaryIntervals;
  let arr = [];
  if (intervals.length > 0) {
    for (let i = 0; i < intervals.length; i++) {
      let minutes;
      if (i === 0) {
        minutes =
          jobDetails.salaryIntervals[i].timeInHalfHours * 30 -
          jobDetails.endTime * 30;
      } else {
        minutes =
          jobDetails.salaryIntervals[i].timeInHalfHours * 30 -
          jobDetails.salaryIntervals[i - 1].timeInHalfHours * 30;
      }
      arr.push({
        Minute: minutes,
        Salary: jobDetails.salaryIntervals[i].salaryPerHour * 100,
      });
    }
  }
  return JSON.stringify(arr);
}

export function stringToSalaryIntervals(
  endtimeInHalfHours: number,
  str: string
) {
  let stringObj: { Minute: number; Salary: number }[] = JSON.parse(str);

  let salaryIntervals: { timeInHalfHours: number; salaryPerHour: string }[] =
    [];
  for (const interval of stringObj) {
    let halfHours = endtimeInHalfHours + interval.Minute / 30;

    salaryIntervals.push({
      salaryPerHour: (interval.Salary / 100).toFixed(2),
      timeInHalfHours: halfHours,
    });

    endtimeInHalfHours = halfHours;
  }
  return salaryIntervals;
}

export async function submitForm(
  state: iEditPostingState,
  useOverBooking: boolean,
  isPrivate: boolean
) {
  let postingsHelper = new PostingHelper();

  let intervalStr = salaryIntervalsToString(state.jobDetails);

  let startTime = moment(state.jobDetails.date);
  startTime.set("hours", 0);
  startTime.set("minutes", state.jobDetails.startTime * 30);

  let endTime = moment(state.jobDetails.date);
  endTime.set("hours", 0);

  if (state.jobDetails.salaryIntervals.length > 0) {
    endTime.set(
      "minutes",
      state.jobDetails.salaryIntervals[
        state.jobDetails.salaryIntervals.length - 1
      ].timeInHalfHours * 30
    );
  } else {
    endTime.set("minutes", state.jobDetails.endTime * 30);
  }

  let amountNeededWithBuffer = useOverBooking
    ? state.overbookAmount
    : state.jobDetails.amountNeeded;

  let response;
  if (state.formState === FormStates.newForm) {
    let posting = new CreatePostingModel({
      SkillId: state.jobDetails.selectedJobtype,
      AddressLine1: state.informationForChabber.address,
      AddressLine2: "",
      City: state.informationForChabber.city,
      ZipCode: state.informationForChabber.zip,

      SalaryPerHour: Math.round(Number(state.jobDetails.salaryPerHour) * 100),
      Description: state.informationForChabber.jobDescription,
      Contact: state.informationForChabber.contact,
      MustBring: state.informationForChabber.chabberMustBring,
      ReferenceKey: state.additionalInfo.InternalRef,

      AmountNeeded: state.jobDetails.amountNeeded,
      AmountNeededWithBuffer: amountNeededWithBuffer,
      AutoHireFavourites: state.additionalInfo.AutohireFavourites,
      CancelInsuranceSelected: state.additionalInfo.CancellationInsurance,
      CoveredByCollectiveAgreement:
        state.additionalInfo.PaidTransportCollectiveAgreement,
      RequiresRelevantExperience: state.additionalInfo.RequireExperience,
      PaysTransport: state.jobDetails.selectedPaidTransport,
      StartAt: startTime.format("YYYY-MM-DD HH:mm:ss"),
      EndAt: endTime.format("YYYY-MM-DD HH:mm:ss"),

      OwnerLoginId: state.additionalInfo.selectedJobOwner,
      AutoHire: 0,
      AutoHireOnCancel: state.additionalInfo.AutoHireOnCancel,
      LikelihoodOfOvertime: +state.jobDetails.selectedOvertime,
      SalaryIntervals: intervalStr,
      CompanyInvoiceRecipientId: state.additionalInfo.invoiceRecipientId || "",
      SelfPaidBreak: state.additionalInfo.SelfPaidBreak,
      MealIncluded: state.additionalInfo.MealIncluded,
    });

    // Set ReferenceKey according to EventType if private
    if (isPrivate) {
      let eventType = state.jobDetails.EventTypes.find(
        (t) => t.Id === state.jobDetails.SelectedEventType
      );
      if (eventType) {
        posting.ReferenceKey = eventType.Type;
      }
    }
    response = await postingsHelper.createPosting(posting);
    let storageHelper = new StorageHelper();
    storageHelper.sendEvent("trackEvent", {
      event: "CreatePosting",
      data: "Freelance",
    });

    if (response && response.Id) {
      await new ChatHelper().createChatNetworkForPosting(response.Id);
    }
  } else {
    let posting = new UpdatePostingModel({
      Id: state.originalPosting!.Id,
      SkillId: state.jobDetails.selectedJobtype,
      AddressLine1: state.informationForChabber.address,
      AddressLine2: "",
      City: state.informationForChabber.city,
      ZipCode: state.informationForChabber.zip,

      SalaryPerHour: Math.round(Number(state.jobDetails.salaryPerHour) * 100),
      Description: state.informationForChabber.jobDescription,
      Contact: state.informationForChabber.contact,
      MustBring: state.informationForChabber.chabberMustBring,
      ReferenceKey: state.additionalInfo.InternalRef,

      AmountNeeded: state.jobDetails.amountNeeded,
      AmountNeededWithBuffer: state.jobDetails.amountNeeded,
      AutoHireFavourites: state.additionalInfo.AutohireFavourites,
      CoveredByCollectiveAgreement:
        state.additionalInfo.PaidTransportCollectiveAgreement,
      RequiresRelevantExperience: state.additionalInfo.RequireExperience,
      PaysTransport: state.jobDetails.selectedPaidTransport,
      StartAt: startTime.format("YYYY-MM-DD HH:mm:ss"),
      EndAt: endTime.format("YYYY-MM-DD HH:mm:ss"),

      OwnerLoginId: state.additionalInfo.selectedJobOwner,
      AutoHire: state.originalPosting ? state.originalPosting.AutoHire : 0,
      AutoHireOnCancel: state.additionalInfo.AutoHireOnCancel,
      LikelihoodOfOvertime: Number(state.jobDetails.selectedOvertime),
      SalaryIntervals: intervalStr,
      CompanyInvoiceRecipientId: state.additionalInfo.invoiceRecipientId || "",
      SelfPaidBreak: state.additionalInfo.SelfPaidBreak,
      MealIncluded: state.additionalInfo.MealIncluded,
    });

    response = await postingsHelper.updatePosting(posting);
  }
  return response;
}

/**
 * @param time in the format of HH:MM
 */
export function stringTimeToHalfHours(time: string) {
  let [hours, minutes] = time.split(":");
  let halfHours = Number(hours) * 2;
  if (minutes === "30") halfHours++;
  return halfHours;
}

export function createTemplateData(
  jobDetails: JobDetailsHolder,
  informationForChabber: iInformationForChabber,
  additionalInfo: iAdditionalInfo
) {
  let startTimeNumber = jobDetails.startTime / 2;
  let StartTime = "";
  if (startTimeNumber - Math.floor(startTimeNumber) === 0.5) {
    StartTime = Math.floor(startTimeNumber) + ":30:00";
  } else {
    StartTime = startTimeNumber + ":00:00";
  }

  let endTimeNumber = jobDetails.endTime / 2;
  let EndTime = "";
  if (endTimeNumber - Math.floor(endTimeNumber) === 0.5) {
    EndTime = Math.floor(endTimeNumber) + ":30:00";
  } else {
    EndTime = endTimeNumber + ":00:00";
  }

  let data: PostingTemplateModel = new PostingTemplateModel({
    SkillId: jobDetails.selectedJobtype,
    AmountNeeded: jobDetails.amountNeeded,
    StartTime,
    EndTime,
    SalaryIntervals: salaryIntervalsToString(jobDetails),
    PaysTransport: jobDetails.selectedPaidTransport,
    LikelihoodOfOvertime: jobDetails.selectedOvertime,
    SalaryPerHour: Number(jobDetails.salaryPerHour) * 100,
    DefaultOwnerLoginId: additionalInfo.selectedJobOwner,

    Addressline1: informationForChabber.address,
    City: informationForChabber.city,
    Description: informationForChabber.jobDescription,
    MustBring: informationForChabber.chabberMustBring,
    ZipCode: informationForChabber.zip,

    ReferenceKey: additionalInfo.InternalRef,
    RequiresRelevantExperience: additionalInfo.RequireExperience,
    CompanyInvoiceRecipientId: additionalInfo.invoiceRecipientId,
    AutoHireOnCancel: additionalInfo.AutoHireOnCancel,
    SelfPaidBreak: additionalInfo.SelfPaidBreak,
    MealIncluded: additionalInfo.MealIncluded,
    CancelInsuranceSelected: additionalInfo.CancellationInsurance,
    AutoHireFavourites: additionalInfo.AutohireFavourites,
    CoveredByCollectiveAgreement:
      additionalInfo.PaidTransportCollectiveAgreement,

    // AutoHireOnCancel: , //FIXME: Not sure what to put here..
  });
  return data;
}
