// @ts-nocheck
//no check because of swipeabledrawer
import React, { useState } from "react";
import { Button, SwipeableDrawer } from "@material-ui/core";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { Chat } from "../../Shared/Chat";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";

interface IProps {
  messageNetworkId: string;
  onOpen: () => void;
}
/*
  Moved outside of Shift component because of state management issues there (race conditions between different state updates). 
  It's aint easy to troubleshot nor fix such issues, much easier to move some part of the state and underlying functionality into new component.
*/
export const ShiftChatDrawerWrapper = ({
  messageNetworkId,
  onOpen,
}: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <Button
        className="white"
        variant="contained"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <span className="btn-icon">
          <i className="fas fa-comment" />
        </span>
        {getLanguage(212, "Chat")}
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        variant="temporary"
        classes={{ paper: "chatDrawer" }}
      >
        <div></div>
        <Chat messageNetworkId={messageNetworkId}></Chat>
      </SwipeableDrawer>
    </>
  );
};
