import { FreelancerModel } from "./FreelancerModel";
import { PostingModel } from "./PostingModel";
import { CurrencyModel } from "./CurrencyModel";
import { CompanyModel } from "./CompanyModel";
import { LateCancelModel } from "./LateCancelModel";

export enum RequestStatuses {
  Open = 0,
  Rejected = 1,
  Accepted = 2,
  Confirmed = 3,
}

export class RequestModel {
  Id: string;
  PostingId: string;
  Posting: PostingModel | null;
  FreelancerId: string;
  Freelancer: FreelancerModel | null; //TODO: it would be preferable to change this variable name to FreelancerProfile as that's what it is called in the object returned from the DB
  FreelancerProfile: FreelancerModel | null; //TODO: Example of the above right here. Because requests dont have a freelancer in them, they have freelancerProfiles.
  CompanyId: string;
  Company: CompanyModel | null;
  CompanyProfile: CompanyModel | null;
  RequestType: number;
  RequestStatus: RequestStatuses;
  SalaryPerHour: number;
  FinalMinutesWorked: number;
  Rating: number;
  WorkDateTime: Date | null;
  EndDateTime: Date | null;
  Created: Date | null;
  LastAction: Date | null;
  SkillId: string;
  //Skill: SkillModel;
  DeclinedByCompany: boolean;
  Charged: boolean;
  LastChargeAttempt: Date | null;
  OverwriteFirstChargeDate: Date | null;
  PostWorkMailSent: boolean;
  Comment: string;
  PaysTransport: number;
  PlatformPercentage: number;
  SmsReminderStatus: number;
  PaymentFee: number;
  PaymentFeePercent: number;
  UseFactoring: boolean;
  FactoringFeePercent: number;
  InvoiceFee: number;
  PlatformFeePercent: number;
  CreatedPostDispute: boolean;
  ChabberCancelledAfterHired: boolean;
  UseManualPayment: boolean;
  CompanyMarkedAsReported: boolean;
  PostingGroupId: string;
  SalaryIntervals: string;
  SalaryPerHourForReporting: number;
  FinalMinutesWorkedForReporting: number;
  ConfirmedDate: Date | null;
  ContractAdditionalText: string;
  TransferedToPayrollSystem: boolean;
  CancelledDateTime: Date | null;
  CancelledNotificationSent: boolean;
  ReasonForCancel: string;
  IsCompensation: boolean;
  DateOfTransferedToPayrollSystem: Date | null;
  WasMinutesLate: number;
  ConfirmedByAdmin: boolean;
  CurrencyId: string;
  InvoiceSendDate: Date | null;
  AutomaticallyReopened: boolean;
  OneTimeFeeRate: number;
  UrgentJobPerDayFeeRate: number;
  VideoId: string;
  VideoUrl: string;
  WorkingMinutesSubtracted: number;
  CancelInsuranceSelected: boolean;
  DateForSalaryPayout: string;
  WorkDateTimeLocal: string;
  EndDateTimeLocal: string;
  FullFee: number;
  FullFeePerHour: number;
  FullSalary: number;
  FullSalaryIncludingFee: number;
  HourlyFeeRate: number;
  HoursAndMinutesAsDecimal: number;
  JobLengthInMinutes: number;
  JobLengthInMinutesIncludingTransport: number;
  MatchingRating: number;
  Currency: CurrencyModel | null;
  //SalaryDeductions: SalaryDeductionModel[];
  vmUnreadMessages: boolean;
  OvertimeAddedInMinutes: number;
  LateCancel: LateCancelModel | null;
  AutoHiredAsFavourite: boolean;
  SalarySupplementPercentage: number;
  SelfPaidBreak: boolean;
  CoveredByCollectiveAgreement: boolean;

  constructor(val: Partial<RequestModel>) {
    this.Id = val.Id || "";
    this.PostingId = val.PostingId || "";
    //this.Posting = val.Posting || '';
    this.FreelancerId = val.FreelancerId || "";
    this.Freelancer = val.Freelancer || null;
    this.FreelancerProfile = val.FreelancerProfile || null;
    this.CompanyId = val.CompanyId || "";
    this.Company = val.Company || null;
    this.CompanyProfile = val.CompanyProfile || null;
    this.RequestType = val.RequestType || 0;
    this.RequestStatus = val.RequestStatus || 0;
    this.SalaryPerHour = val.SalaryPerHour || 0;
    this.FinalMinutesWorked = val.FinalMinutesWorked || 0;
    this.Rating = val.Rating || 0;
    this.WorkDateTime = val.WorkDateTime || null;
    this.EndDateTime = val.EndDateTime || null;
    this.Created = val.Created || null;
    this.LastAction = val.LastAction || null;
    this.SkillId = val.SkillId || "";
    //this.Skill = val.Skill || null;
    this.DeclinedByCompany = val.DeclinedByCompany || false;
    this.Charged = val.Charged || false;
    this.LastChargeAttempt = val.LastChargeAttempt || null;
    this.OverwriteFirstChargeDate = val.OverwriteFirstChargeDate || null;
    this.PostWorkMailSent = val.PostWorkMailSent || false;
    this.Comment = val.Comment || "";
    this.PaysTransport = val.PaysTransport || 0;
    this.PlatformPercentage = val.PlatformPercentage || 0;
    this.SmsReminderStatus = val.SmsReminderStatus || 0;
    this.PaymentFee = val.PaymentFee || 0;
    this.PaymentFeePercent = val.PaymentFeePercent || 0;
    this.UseFactoring = val.UseFactoring || false;
    this.FactoringFeePercent = val.FactoringFeePercent || 0;
    this.InvoiceFee = val.InvoiceFee || 0;
    this.PlatformFeePercent = val.PlatformFeePercent || 0;
    this.CreatedPostDispute = val.CreatedPostDispute || false;
    this.ChabberCancelledAfterHired = val.ChabberCancelledAfterHired || false;
    this.UseManualPayment = val.UseManualPayment || false;
    this.CompanyMarkedAsReported = val.CompanyMarkedAsReported || false;
    this.PostingGroupId = val.PostingGroupId || "";
    this.SalaryIntervals = val.SalaryIntervals || "";
    this.SalaryPerHourForReporting = val.SalaryPerHourForReporting || 0;
    this.FinalMinutesWorkedForReporting = val.FinalMinutesWorkedForReporting || 0;
    this.ConfirmedDate = val.ConfirmedDate || null;
    this.ContractAdditionalText = val.ContractAdditionalText || "";
    this.TransferedToPayrollSystem = val.TransferedToPayrollSystem || false;
    this.CancelledDateTime = val.CancelledDateTime || null;
    this.CancelledNotificationSent = val.CancelledNotificationSent || false;
    this.ReasonForCancel = val.ReasonForCancel || "";
    this.IsCompensation = val.IsCompensation || false;
    this.DateOfTransferedToPayrollSystem = val.DateOfTransferedToPayrollSystem || null;
    this.WasMinutesLate = val.WasMinutesLate || 0;
    this.ConfirmedByAdmin = val.ConfirmedByAdmin || false;
    this.CurrencyId = val.CurrencyId || "";
    this.InvoiceSendDate = val.InvoiceSendDate || null;
    this.AutomaticallyReopened = val.AutomaticallyReopened || false;
    this.OneTimeFeeRate = val.OneTimeFeeRate || 0;
    this.HourlyFeeRate = val.HourlyFeeRate || 0;
    this.UrgentJobPerDayFeeRate = val.UrgentJobPerDayFeeRate || 0;
    this.VideoId = val.VideoId || "";
    this.VideoUrl = val.VideoUrl || "";
    this.WorkingMinutesSubtracted = val.WorkingMinutesSubtracted || 0;
    this.CancelInsuranceSelected = val.CancelInsuranceSelected || false;
    this.Posting = val.Posting || null;
    this.DateForSalaryPayout = val.DateForSalaryPayout || ";";
    this.WorkDateTimeLocal = val.WorkDateTimeLocal || "";
    this.EndDateTimeLocal = val.EndDateTimeLocal || "";
    this.FullFee = val.FullFee || 0;
    this.FullFeePerHour = val.FullFeePerHour || 0;
    this.FullSalary = val.FullSalary || 0;
    this.FullSalaryIncludingFee = val.FullSalaryIncludingFee || 0;
    this.HourlyFeeRate = val.HourlyFeeRate || 0;
    this.HoursAndMinutesAsDecimal = val.HoursAndMinutesAsDecimal || 0;
    this.JobLengthInMinutes = val.JobLengthInMinutes || 0;
    this.JobLengthInMinutesIncludingTransport = val.JobLengthInMinutesIncludingTransport || 0;
    this.MatchingRating = val.MatchingRating || 0;
    this.Currency = val.Currency || null;
    //TODO: this should not have VM stuff
    this.vmUnreadMessages = val.vmUnreadMessages || false;
    this.OvertimeAddedInMinutes = val.OvertimeAddedInMinutes || 0;
    this.LateCancel = val.LateCancel || null;
    this.AutoHiredAsFavourite = val.AutoHiredAsFavourite || false;
    this.SalarySupplementPercentage = val.SalarySupplementPercentage || 0;
    this.SelfPaidBreak = val.SelfPaidBreak || false;
    this.CoveredByCollectiveAgreement = val.CoveredByCollectiveAgreement || false;

    //this.SalaryDeductions = val.SalaryDeductions || [];
  }
}
